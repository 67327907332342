<template>
  <div
    class="authors"
    :class="isTablet"
  >
    <div
      v-if="author"
      class="author"
    >
      <a
        v-if="$isAMP"
        class="js-author-profile-link"
        :href="author.profile"
        :data-vars-link-href="$isAMP ? getAnalyticsLink(author.profile) : undefined"
        :data-vars-author-name="$isAMP ? author.name : undefined"
      >
        <component
          :is="'amp-img'"
          class="author__avatar"
          height="40"
          width="40"
          :src="author.avatar"
          layout="responsive"
          :alt="`Фото автора ${author.name}`"
        />
      </a>
      <a
        v-else
        :href="author.profile"
        @click="sendAnalytics(author, 'автора', 'автору')"
      >
        <img
          class="author__avatar"
          :src="author.avatar"
          :alt="`Фото автора ${author.name}`"
        />
      </a>
      <div
        class="author__about mr-3"
        :class="checkDescription(author)"
      >
        <a
          class="author__name js-author-profile-link"
          :href="author.profile"
          :data-vars-link-href="$isAMP ? getAnalyticsLink(author.profile) : undefined"
          :data-vars-author-name="$isAMP ? author.name : undefined"
          @click="sendAnalytics(author, 'автора', 'автору')"
          >{{ author.name }}</a
        >
        <p
          class="author__description"
          @click="(event) => trackLinkClick(event, 'автора')"
          v-html="author.expertise"
        />
      </div>
    </div>
    <div
      v-if="expert && expert.name"
      class="author"
      :class="isTablet"
    >
      <a
        v-if="$isAMP"
        class="js-expert-profile-link"
        :href="expert.profile"
        :data-vars-link-href="$isAMP ? getAnalyticsLink(expert.profile) : undefined"
        :data-vars-expert-name="$isAMP ? expert.name : undefined"
      >
        <component
          :is="'amp-img'"
          class="author__avatar"
          height="40"
          width="40"
          :src="expert.avatar"
          layout="responsive"
          :alt="`Фото эксперта ${expert.name}`"
        />
      </a>
      <a
        v-else
        :href="expert.profile"
        @click="sendAnalytics(expert, 'эксперта', 'эксперту')"
      >
        <img
          class="author__avatar"
          :src="expert.avatar"
          :alt="`Фото эксперта ${expert.name}`"
        />
      </a>
      <div
        class="author__about"
        :class="checkDescription(expert)"
      >
        <a
          class="author__name js-expert-profile-link"
          :href="expert.profile"
          :data-vars-link-href="$isAMP ? getAnalyticsLink(expert.profile) : undefined"
          :data-vars-expert-name="$isAMP ? expert.name : undefined"
          @click="sendAnalytics(expert, 'эксперта', 'эксперту')"
          >{{ expert.name }}</a
        >
        <p
          class="author__description"
          @click="(event) => trackLinkClick(event, 'эксперта')"
          v-html="expert.expertise"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { getFullUrl } from '~/utils';
  import getSlugFromUrl from '~/utils/getSlugFromUrl';

  type ProfileType = 'author' | 'expert';

  export default defineNuxtComponent({
    name: 'AuthorsExperts',
    props: {
      // TODO: убрать amp
      // в шаблонах можно использовать $isAMP
      // стили разнести на обычные и амповские
      amp: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      author: {
        type: Object as PropType<IArticle['author']>,
        default: null,
      },
      expert: {
        type: Object as PropType<IArticle['expert']>,
        default: null,
      },
    },
    computed: {
      isTablet(): Record<string, boolean> {
        return {
          'authors-tablet': !this.$isAMP,
        };
      },
    },

    methods: {
      checkDescription(person: IArticle['author'] | IArticle['expert']): string {
        return person.expertise ? 'author__about-mt-expertise' : 'author__about-mt-empty';
      },

      trackLinkClick(
        event: MouseEvent,
        authorOrExpert: IArticle['author'] | IArticle['expert'],
        profileType: ProfileType,
      ) {
        if (!event.target) {
          return;
        }
        const parentLink = (event.target as HTMLElement).closest('a');
        if (!parentLink) {
          return;
        }

        this.sendAnalytics(authorOrExpert, profileType, 'описанию', parentLink.href);
      },

      getAnalyticsLink(link: string): string {
        return getFullUrl(link);
      },

      sendAnalytics(
        authorOrExpert: IArticle['author'] | IArticle['expert'],
        profileType: string,
        clickSource: string,
        linkHref?: string,
      ): void {
        const route = useRoute();
        this.$sendYandexMetrika({
          level1: 'Публичный профиль автора_переход в профиль ' + profileType,
          level2: getSlugFromUrl(route.fullPath),
          level4: linkHref ?? authorOrExpert.profile,
          level5: authorOrExpert.name,
          level6: 'Клик по ' + clickSource,
        });
      },
    },
  });
</script>

<style lang="scss">
  .authors {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;

    @include tablet {
      flex-direction: row;
      gap: 24px;
    }
  }

  .author {
    display: flex;
    align-items: flex-start;

    @include tablet {
      margin-top: var(--author-mt);
    }

    &__name {
      cursor: pointer;
      max-width: 250px;
      font-family: $secondFont;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      color: #000;
    }

    &__description,
    p {
      max-width: 200px;
      overflow-wrap: break-word;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      a {
        cursor: pointer;
        color: var(--main-color-2);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__avatar {
      cursor: pointer;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      min-width: 40px;

      @include tablet {
        min-height: 48px;
        max-height: 59px;
        min-width: 48px;
        max-width: 59px;
      }
    }

    &__about {
      overflow-wrap: break-word;
      margin-left: 8px;
    }
  }
</style>
